import {Fragment, ReactElement, useEffect, useRef, useState} from "react";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import FrameButton from "@/components/buttons/FrameButton";
import {HiSparkles} from "react-icons/hi";
import {RiVipDiamondLine} from "react-icons/ri";
import {GoGift} from "react-icons/go";
import {ReactComponent as HandSave} from "../svgs/hand-save.svg";
import {ReactComponent as HandDpay} from "../svgs/hand-dpay.svg";
import classNames from "classnames";
import {useGetUnclaimedNFTSupply} from "@/hooks/useGetUnclaimedNFTSupply";
import useWindowSize, {WindowBreakpointSizes} from "@/hooks/useWindowSize";
import BackgroundTriangleSVG from "@/components/BackgroundTriangleSVG";
import AnimatedNumbers from "react-animated-numbers";
import {easings} from "@react-spring/web";
import MintIndustryPassModal from "@/components/modals/mintIndustryPassModal/MintIndustryPassModal";
import {useParams} from "react-router";
import MintIndustryPassSuccessWrapper
    from "@/components/modals/mintIndustryPassModal/MintIndustryPassSuccessWrapper";
import useThemePreference from "@/hooks/useThemePreference";
import {useGate} from "statsig-react";

interface IndustryPassPromoCardMetadata {
    image: string;
    title: string;
    description: string;
}

interface IndustryPassInfoCardMetadata {
    icon: ReactElement;
    text: string;
}

function TheIndustryPassPage(): ReactElement {
    const {getThemedImageUrl, isOnDarkMode} = useThemePreference();
    const {nftPurchaseId} = useParams<{ nftPurchaseId?: string; }>();
    const paymentRef = useRef(null);
    const windowBreakPoint = getWindowBreakpoint(useWindowSize()[0]);
    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);
    const [
        animateToNumber,
        setAnimateToNumber,
    ] = useState<number>(Math.ceil(Math.random() * 9000) + 1000);
    const [
        resetFlowFromSuccess,
        setResetFlowFromSuccess,
    ] = useState<boolean>(false);


    const {data: unclaimedNFTAmount} = useGetUnclaimedNFTSupply();

    useEffect(() => {
        if (!unclaimedNFTAmount || animateToNumber !== unclaimedNFTAmount?.amount) {
            setTimeout(() => setAnimateToNumber(unclaimedNFTAmount
                ? unclaimedNFTAmount.amount
                : Math.ceil(Math.random() * 9000) + 1000), 1000);
        }
    }, [animateToNumber]);

    useEffect(() => {
        if (nftPurchaseId) {
            // Wait a second for assets to finish loading
            setTimeout(scrollToPayment, 1000);
        }
    }, [nftPurchaseId]);
    function scrollToPayment(): void {
        paymentRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }

    function getWindowBreakpoint(windowWidth: number): WindowBreakpointSizes {
        if (windowWidth < 768) {
            return WindowBreakpointSizes.SMALL;
        }
        if (windowWidth >= 768 && windowWidth < 992) {
            return WindowBreakpointSizes.MEDIUM;
        }
        return WindowBreakpointSizes.LARGE;

    }

    function resetSuccess(): void {
        setResetFlowFromSuccess(!resetFlowFromSuccess);
    }

    function renderIndustryPassInfoLines(data: IndustryPassInfoCardMetadata): ReactElement {
        return (
            <div
                className="the-industry-pass-page_header_info-card_text-line"
                key={data.text}
            >
                <div className="the-industry-pass-page_header_info-card_icon">
                    {data.icon}
                </div>
                <p>{data.text}</p>
            </div>
        );
    }

    function renderIndustryPassCards(data: IndustryPassPromoCardMetadata, index: number): ReactElement {
        return (
            <Fragment key={index}>
                <div
                    className="the-industry-pass-page_promo-cards_card"
                >
                    <div className={classNames(
                        "the-industry-pass-page_promo-cards_card_image-container",
                        index > 2 && windowBreakPoint !== WindowBreakpointSizes.SMALL && "the-industry-pass-page_promo-cards_card_image-container_shift-up",
                    )}>
                        <img
                            src={data.image}
                            className="the-industry-pass-page_promo-cards_card_image-container_image"
                            alt={data.title}
                        />
                    </div>
                    <div className="the-industry-pass-page_promo-cards_card_texts">
                        <h3>{data.title}</h3>
                        <p>{data.description}</p>
                    </div>
                </div>
                {index === 2 && <div className="the-industry-pass-page_promo-cards_break"/>}
            </Fragment>
        );
    }

    const industryPassCardsInfo: IndustryPassPromoCardMetadata[] = [
        {
            image: getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/Delivery.webp`),
            title: "$0 Delivery Fee",
            description: "One time purchase of the Industry Pass for unlimited free delivery! Holders of the Industry Pass pay $0 delivery fees on all DevourGO orders.",
        },
        ...dpayStatus ? [
            {
                image: `${import.meta.env.VITE_CDN_URL}/images/load-FUEL-Mobile.webp`,
                title: `5% ${import.meta.env.VITE_TOKEN_NAME} Back`,
                description: `Introducing the ${import.meta.env.VITE_TOKEN_NAME} Back Program – Enjoy 5% back in ${import.meta.env.VITE_TOKEN_NAME} on every DevourGO order, automatically credited to your account.`,
            },
        ] : [],
        {
            image: getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/Unlock-GoVIP.webp`),
            title: "Unlock GoVIP Level 4",
            description: "Experience GoVIP to the fullest! Level up your account and be rewarded with crypto, NFT loot boxes, and other prizes. ",
        },
        ...dpayStatus ? [
            {
                image: getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/DPAY-Reward.webp`),
                title: `$10 Monthly\n ${import.meta.env.VITE_TOKEN_NAME} Reward`,
                description: `On the 1st of every month, registered users of DevourGO holding a Industry Pass receive $10 per pass in ${import.meta.env.VITE_TOKEN_NAME} directly in their DevourGO account.`,
            },
        ] : [],
        {
            image: getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/Promo.webp`),
            title: "Exclusive Promotions",
            description: "Embrace the full spectrum of exclusivity as a Industry Pass holder! gaining access to exclusive promotions and experiences from Devour and its restaurant partners!",
        },
    ];

    const industryPassInfoCardInfo: IndustryPassInfoCardMetadata[] = [
        {
            icon: <HandSave/>,
            text: "Unlimited $0 Delivery",
        },
        {
            icon: <RiVipDiamondLine className="the-industry-pass-page_header_info-card_icon"/>,
            text: "Continue Past GoVIP Level 4",
        },
        ...dpayStatus ? [
            {
                icon: <GoGift className="the-industry-pass-page_header_info-card_icon"/>,
                text: `$120 in Annual ${import.meta.env.VITE_TOKEN_NAME} Rewards`,
            },
            {
                icon: <HandDpay/>,
                text: `5% ${import.meta.env.VITE_TOKEN_NAME} Back on All Orders`,
            },
        ] : [],
    ];

    return (
        <>
            <div className="the-industry-pass-page">
                <div
                    className="the-industry-pass-page_header"
                    style={{
                        backgroundImage: `url(${getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/IndustryPass-bnr-FUEL-${windowBreakPoint}.webp`)})`,
                    }}
                >
                    <div className="the-industry-pass-page_header_nft-counter">
                        <div className="the-industry-pass-page_header_nft-counter_number-counter">
                            <span>
                                <AnimatedNumbers
                                    animateToNumber={animateToNumber}
                                    configs={() => {
                                        return {
                                            mass: 1,
                                            easing: unclaimedNFTAmount?.amount === animateToNumber
                                                ? easings.easeOutSine
                                                : easings.linear,
                                            duration: 1000,
                                        };
                                    }}
                                />
                            </span>
                            <p className="the-industry-pass-page_header_nft-counter_number-counter">
																Pass{unclaimedNFTAmount?.amount === 1
                                    ? ""
                                    : "es"} Remaining
                            </p>
                        </div>
                    </div>
                    <div className="the-industry-pass-page_header_text-container">
                        {!isOnDarkMode && <h1 className="the-industry-pass-page_header_slogan">
														UNLOCK {isMobile && !isTablet || windowBreakPoint === WindowBreakpointSizes.SMALL
                                ? "\n"
                                : "BIGGER SAVINGS WITH THE \n"}
                            <span className="the-industry-pass-page_header_highlight">
                                INDUSTRY{isMobile && !isTablet || windowBreakPoint === WindowBreakpointSizes.SMALL ? "\n" : " "}PASS
                            </span>
                        </h1>}

                        {isOnDarkMode && <h1 className="the-industry-pass-page_header_slogan">
														Unlock {isMobile && !isTablet || windowBreakPoint === WindowBreakpointSizes.SMALL
                                ? "\n"
                                : "Bigger Savings \n With"}

                            <span className="the-industry-pass-page_header_highlight">
                                 The Industry{isMobile && !isTablet || windowBreakPoint === WindowBreakpointSizes.SMALL ? "\n" : " "}Pass
                            </span>
                        </h1>}
                        <p>One time purchase of $150. No Subscription required.</p>
                    </div>
                    <div
                        className={classNames(
                            "the-industry-pass-page_header_button",
                            isDesktop && "the-industry-pass-page_header_button_desktop",
                        )}
                    >
                        <FrameButton
                            color="white-drop-shadow-devour"
                            size="large"
                            className="the-industry-pass-page_header_button_frame-button"
                            leftIcon={HiSparkles}
                            onClick={scrollToPayment}
                        >
														Start Saving Now!
                        </FrameButton>
                    </div>
                    <div className="the-industry-pass-page_header_info-card">
                        <h2>Industry Pass</h2>
                        <h4>One time purchase of $150</h4>

                        <div className="the-industry-pass-page_header_info-card_text-container">
                            {industryPassInfoCardInfo.map(renderIndustryPassInfoLines)}
                        </div>

                        <FrameButton
                            color="purple"
                            size="narrow"
                            className="the-industry-pass-page_header_info-card_button"
                            onClick={scrollToPayment}
                        >
														Purchase
                        </FrameButton>
                    </div>
                </div>
                {!isOnDarkMode && <BackgroundTriangleSVG
                    size={windowBreakPoint}
                    colour="white"
                />}
                <div
                    className="the-industry-pass-page_devour-texture"
                    style={{
                        backgroundImage: `url(${getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/DevourGo-texture-1.webp`)})`,
                    }}
                />
                <div
                    className={classNames(
                        "the-industry-pass-page_promo",
                        isTablet && "the-industry-pass-page_promo_tablet",
                    )}
                >
                    <div className={classNames(
                        "the-industry-pass-page_promo_images-container",
                        isMobile && "the-industry-pass-page_promo_images-container_mobile",
                    )}>
                        {!isMobile && <img
                            src={`${import.meta.env.VITE_CDN_URL}/images/IndustryPass-NFT-1.webp`}
                            className="the-industry-pass-page_promo_image"
                            alt="industry-pass-nft1"
                        />}
                        <img
                            src={`${import.meta.env.VITE_CDN_URL}/images/IndustryPass-Logo.webp`}
                            className="the-industry-pass-page_promo_image_logo"
                            alt="industry-pass-logo"
                        />
                        {!isMobile && <img
                            src={`${import.meta.env.VITE_CDN_URL}/images/IndustryPass-NFT-2.webp`}
                            className="the-industry-pass-page_promo_image"
                            alt="industry-pass-nft2"
                        />}
                    </div>
                </div>
                <div
                    className={classNames(
                        "the-industry-pass-page_description",
                        isTablet && "the-industry-pass-page_description_tablet",
                    )}
                >
                    <h1>
                        <span
                            className="the-industry-pass-page_description_slogan">WHAT IS THE {"\n"}</span>
                        <span className="the-industry-pass-page_description_highlight">INDUSTRY PASS?</span>
                        {/* <span className="the-industry-pass-page_description_slogan">?</span>*/}
                    </h1>
                    <div className="the-industry-pass-page_description_content">
                        <p>
														The Industry Pass is a tribute to the beating heart of the restaurant
														industry, shining a
														spotlight on the unsung heroes who make it all possible! When you grab
														an Industry Pass from our
														collection, you unlock a world of exclusive benefits and utility.
                        </p>
                    </div>
                </div>
                <div
                    className="the-industry-pass-page_promo-cards"
                    style={{
                        backgroundImage: !isOnDarkMode
                            ? `url(${getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/DevourGo-texture-2.webp`)})`
                            : "none",
                    }}
                >
                    {industryPassCardsInfo.map((card, index) => renderIndustryPassCards(card, index))}
                </div>
                {
                    !isOnDarkMode && <BackgroundTriangleSVG
                        size={windowBreakPoint}
                        rotated={true}
                        flipped={true}
                        colour="white"
                    />
                }
                <div
                    className="the-industry-pass-page_purchase"
                    style={{
                        backgroundImage: `url(${getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/IndustryPass-bnr-bot-${windowBreakPoint}.webp`)})`,
                    }}
                >

                    <div
                        className="the-industry-pass-page_purchase_text-container"
                        ref={paymentRef}
                    >
                        <p>The Industry NFT Collection</p>
                        <h1 className="the-industry-pass-page_purchase_slogan">
														GET YOUR VIP PASS
                        </h1>
                    </div>
                    {nftPurchaseId && !resetFlowFromSuccess
                        ? <MintIndustryPassSuccessWrapper
                            resetSuccess={resetSuccess}
                        />
                        : <MintIndustryPassModal/>
                    }
                </div>
            </div>
        </>
    );
}

export default TheIndustryPassPage;
