import { ButtonHTMLAttributes, ChangeEventHandler, FormEvent, ReactElement, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OnboardingApi, ReferralLinkTextBody, ReferralsApi, UsersApi } from "@devour/client";
import { IStore } from "../redux/defaultStore";
import FrameButton from "../components/buttons/FrameButton";
import AuthHeaderLogo from "../components/auth/AuthHeaderLogo";
import {
    addError,
    decrementLoading,
    incrementLoading,
    toggleLoginOpen,
    updateAnonymousSpin,
    updateCurrentUser,
} from "../redux/meta/metaActions";
import { magic } from "../utils/magic";
import getConfig, { getMagicConfig } from "../utils/getConfig";
import { AiFillCheckCircle } from "react-icons/ai";
import { login } from "../redux/auth/authActions";
import { getRedirectRoute } from "../utils/redirectRouteHandler";
import * as Sentry from "@sentry/react";
import { isPhantomApp } from "@/utils/isGoNative";
import classNames from "classnames";
import { emitLoginEvent, emitRegisterEvent } from "@/hooks/useOnLogin";
import getAnonymousSpin from "@/utils/getAnonymousSpin";
import { AnonymousSpinModalShownKey } from "@/components/modals/GoVipSpin2WinModal";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";

interface Props {
    isOnDialog?: boolean;
}

function MagicLogin(props: Props): ReactElement {
    // keep order
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirect = searchParams.get("redirect");
    const signedUrlKey = searchParams.get("suk");
    const [signedUrlSubmitted, setSignedUrlSubmitted] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");

    const referralCodeQuery = searchParams.get("ref");
    const [referralLinkText, setReferralLinkText] = useState<ReferralLinkTextBody | undefined>(undefined);

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const reduxReferralCode = useSelector((store: IStore) => store.affiliateStore.referralCode);
    const reduxReferralExpiry = useSelector((store: IStore) => store.affiliateStore.referralExpiry);
    const reduxReferralValid = reduxReferralCode && Date.now() < reduxReferralExpiry ? reduxReferralCode : "";
    const [referralCode, setReferralCode] = useState(reduxReferralValid ?? referralCodeQuery ?? "");
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const lastRestaurantId = useSelector((store: IStore) => store.metaStore.lastRestaurantId);
    const lastMenuOrderId = useSelector((store: IStore) => store.metaStore.lastMenuOrderId);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const { sendMessageToOW, isOnOverwolf } = useOverwolfInterop();
    const addressBooks = currentUser?.user?.addresses;

    useEffect(() => {
        async function handleloggedInTrigger() {
            if (fullToken) {
                if (signedUrlKey && !signedUrlSubmitted) {
                    await new OnboardingApi(getConfig(fullToken)).mergeUserWithSignedUrlKey({
                        signedUrlKey: signedUrlKey,
                    });
                    setSignedUrlSubmitted(true);

                    // grab the profile to save into redux
                    const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
                    await dispatch(updateCurrentUser(userRes));
                }
                if (!props.isOnDialog) {
                    // redirect the user to the appropriate page
                    if (isPhantomApp()) {
                        navigate("/gofriends", { replace: true });
                    } else {
                        navigate(
                            await getRedirectRoute(
                                redirect,
                                lastSearchedPlaceId,
                                lastRestaurantId,
                                lastMenuOrderId,
                                addressBooks,
                            ),
                            { replace: true },
                        );
                    }
                } else {
                    emitLoginEvent();
                }
                const anonymousSpin = getAnonymousSpin();
                if (anonymousSpin) {
                    dispatch(updateAnonymousSpin(undefined));
                    window[AnonymousSpinModalShownKey] = false;
                }
            }
        }

        void handleloggedInTrigger();
    }, [fullToken, props.isOnDialog]);

    useEffect(() => {
        /**
         * Get the current referral link text.
         *
         */
        async function getOnboardingData() {
            dispatch(incrementLoading());
            try {
                const formData = await new OnboardingApi(getConfig()).getOnboardingInformation({
                    signedUrlKey: signedUrlKey,
                });

                setEmail(formData.email || "");
            } catch (e) {
                dispatch(await addError(e));
            } finally {
                dispatch(decrementLoading());
            }
        }

        // only render if there was a referral code stored
        if (signedUrlKey) {
            void getOnboardingData();
        }
    }, [signedUrlKey]);

    useEffect(() => {
        /**
         * Get the current referral link text.
         *
         */
        async function getReferralLinkText() {
            try {
                const formData = await new ReferralsApi(getConfig()).getReferralLinkText({
                    referralCode: reduxReferralCode,
                });

                setReferralLinkText({
                    header: formData.header,
                    description: formData.description,
                    relationshipType: formData.relationshipType,
                });
            } catch (e) {
                dispatch(await addError(e));
            } finally {
                dispatch(decrementLoading());
            }
        }

        // only render if there was a referral code stored
        if (reduxReferralCode) {
            void getReferralLinkText();
        }
    }, [reduxReferralCode]);

    const emailOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value);
    };

    const refCodeOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setReferralCode(e.target.value);
    };

    async function loginWithOtp(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        const magicCredential = await magic.auth.loginWithEmailOTP({ email });
        const redirectExists = redirect != null && redirect !== "" && redirect !== "null";

        try {
            // call the login api with the magic_credential, if the API returns 200 with a token, then log the user in as normal
            const res = await new UsersApi(getMagicConfig(magicCredential)).magicLogin();

            // grab the profile to save into redux
            const userRes = await new UsersApi(getConfig(res.token)).getProfile();

            //  save the token and profile into redux
            sendMessageToOW({ type: "de:data",
                isLogin: true,
                payload: { token: res.token,
                    userData: userRes } });
            dispatch(login(res.token));
            dispatch(updateCurrentUser(userRes));

            Sentry.setUser({
                email: userRes.user.email,
            });
            Sentry.setContext("userInfo", userRes);
            dispatch(toggleLoginOpen(false));

            /*
             * login redirect is handled above via `handleloggedInTrigger()`
             * navigate(await getRedirectRoute(redirect, lastSearchedPlaceId, lastRestaurantId, lastMenuOrderId, addressBooks), {replace: true});
             */
        } catch (err) {
            /*
             * if the API returns an error and the error has status 403, then the user needs to be redirected to the signup page
             * this should also include a ?redirect query param if one is provided on this page
             */
            if (err.status === 401) {
                await submitSignUp();
            } else {
                dispatch(await addError(err));
                if (!props.isOnDialog) {
                    navigate(`/log-in${redirectExists ? `&redirect=${redirect}` : ""}`, { replace: true });
                }
            }
        }
    }

    async function submitSignUp() {
        dispatch(incrementLoading());

        try {
            const magicCredential = await magic.auth.loginWithEmailOTP({ email });
            // call the login api with the magic_credential, if the API returns 200 with a token, then log the user in as normal
            const anonymousSpin = getAnonymousSpin();
            const res = await new UsersApi(getMagicConfig(magicCredential)).register({
                registerBody: {
                    anonymousSpinValue: anonymousSpin?.spinValue,
                },
            });
            if (referralCode) {
                try {
                    await new ReferralsApi(getConfig(res.token)).createReferralRelationship({
                        createReferralRelationshipBody: {
                            referralCode: referralCode,
                        },
                    });
                } catch (err) {
                    console.error("Unable to update referral code", err);
                }
            }

            if (signedUrlKey) {
                await new OnboardingApi(getConfig(res.token)).mergeUserWithSignedUrlKey({
                    signedUrlKey: signedUrlKey,
                });
            }

            // grab the profile to save into redux
            const userRes = await new UsersApi(getConfig(res.token)).getProfile();

            // login to magic locally
            if (await magic.user.isLoggedIn() === false) {
                await magic.auth.loginWithCredential(magicCredential);
            }

            //  save the token and profile into redux
            sendMessageToOW({ type: "de:data",
                isLogin: true,
                payload: { token: res.token,
                    userData: userRes } });
            dispatch(login(res.token));
            dispatch(updateCurrentUser(userRes));
            emitRegisterEvent();
            if (anonymousSpin) {
                dispatch(updateAnonymousSpin(undefined));
                window[AnonymousSpinModalShownKey] = false;
            }
            dispatch(toggleLoginOpen(false));
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    return (
        <div
            className={classNames("login-page", {
                "is-on-dialog": !!props.isOnDialog,
            })}
        >
            {!props.isOnDialog &&
                <div className="login-page_spacer-top">
                    <AuthHeaderLogo />
                </div>
            }

            <div className="login-page_content">
                {!isOnOverwolf &&
                    <>
                        <h3 className="login-page_content_title">Craving something different</h3>
                        <p className="login-page_content_subtitle">
                            DevourGO delivers more than just food — serving up a whole new way to eat, play, and earn.
                        </p>
                    </>
                }

                {reduxReferralValid && referralLinkText &&
                    <div className="sign-up_content_referral-link-container">
                        <div className="sign-up_content_referral-link-container_header">
                            <AiFillCheckCircle className="sign-up_content_referral-link-container_header_icon" />
                            <p className="sign-up_content_referral-link-container_text">{referralLinkText.header}</p>
                        </div>
                        {referralLinkText.description && referralLinkText.description !== "" &&
                            <p className="sign-up_content_referral-link-container_text">
                                {referralLinkText.description}
                            </p>
                        }
                    </div>
                }

                <h4 className="login-page_content_login-text">
                    {!isOnOverwolf ? "Login or Sign Up 🚀" : "Login or Sign Up"}
                </h4>
                {isOnOverwolf &&
                    <p className="login-page_content_login-description">
                        DevourGO delivers more than just food — serving up a whole new way to eat, play, and earn!
                    </p>
                }
                <form onSubmit={loginWithOtp}>
                    <div className="login-page_content_email-container">
                        <label>Email</label>
                        <input
                            type="email"
                            required
                            placeholder="Enter your email address"
                            value={email}
                            onChange={emailOnChange}
                        />
                    </div>

                    {!isOnOverwolf &&
                        <div className="login-page_content_referral-container">
                            <label>Referral code</label>
                            <input
                                placeholder="Enter your referral code (optional)"
                                value={referralCode}
                                onChange={refCodeOnChange}
                            />
                        </div>
                    }
                    <br />

                    <FrameButton<ButtonHTMLAttributes<HTMLButtonElement>>
                        color={!isOnOverwolf ? "purple" : "purple-blue-gradient-shadow"}
                        size="normal"
                        className="login-page_content_login-button"
                        forwardProps={{ type: "submit",
                            disabled: email === "" }}
                    >
                        Continue
                    </FrameButton>
                    {!isOnOverwolf && <br />}
                </form>

                {!isOnOverwolf &&
                    <div className="login-page_content_other-actions">
                        <p className="login-page_content_other-actions_owner">
                            Are you a restaurant owner?{" "}
                            <a href={import.meta.env.VITE_MERCHANT_URL} rel="noopener noreferrer">
                                Click here
                            </a>
                        </p>
                    </div>
                }

                <div className="login-page_content_legal">
                    <hr />
                    <p className="login-page_content_legal_p">
                        By clicking “Continue with Email” above or by using any of the methods under "log in or sign up
                        with" to continue to DevourGO, you acknowledge that you have read and understood, and agree to
                        DevourGO’s <Link to="/terms-of-use">Terms of Use</Link>,{" "}
                        <Link to="/privacy-policy">Privacy Policy</Link>, and{" "}
                        <Link to="/cookie-policy">Cookie Policy</Link>
                    </p>
                </div>
            </div>

            <div className="login-page_spacer-bottom" />
        </div>
    );
}

export default MagicLogin;
