import {Dispatch, ReactElement, SetStateAction, useState} from "react";
import RestaurantSearchAddressButton from "./RestaurantSearchAddressButton";
import HandoffToggle from "@/components/HandoffToggle";
import {RestaurantSearchFiltersQuery} from "@/utils/restaurantsSearchFilter";
import RestaurantSearchTextInput from "@/pages/restaurants/components/RestaurantSearchTextInput";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import RestaurantSearchTextInputMobile from "@/pages/restaurants/components/RestaurantSearchTextInputMobile";
import {useDispatch} from "react-redux";
import {HandoffOptions} from "@devour/client";
import {toggleOrderHandoff} from "@/redux/meta/metaActions";
import useThemePreference from "@/hooks/useThemePreference";
import DevourTopNavUserData from "@/components/DevourTopNavUserData";
import SignUpToast from "@/components/SignUpToast";
import GoVipRestaurantSearchBanner from "@/components/goVip/restaurant-search/GoVipRestaurantSearchBanner";

interface Props {
    resultsWithoutFiltersCounter: number;
    restaurantFilters: RestaurantSearchFiltersQuery;
    updateRestaurantFilters: Dispatch<SetStateAction<RestaurantSearchFiltersQuery>>;
}

function RestaurantSearchTopBar(props: Props): ReactElement {
    const dispatch = useDispatch();
    const { getThemedImageUrl } = useThemePreference();
    const [showToast, setShowToast] = useState<boolean>(false);
    const handleToastDismissal = () => setShowToast(false);
    const isOnMobile = isMobile && !isTablet;
    return (
        <>
            <SignUpToast
                message='Join DevourGO and start your GoFriends journey!'
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />

            <div className="restaurants-search-top-bar">
                <div className="restaurants-search-top-bar_top">
                    <div className="restaurants-search-top-bar_logo">
                        <img
                            src={getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/DevourGO-Icon.webp`)}
                            alt="DevourGO Logo"
                        />
                    </div>
                    <div className='restaurants-search-top-bar_main'>
                        <div className="restaurants-search-top-bar_main_address">
                            <RestaurantSearchAddressButton />
                        </div>
                        {isDesktop &&
                    <div className='restaurants-search-top-bar_main_handoff'>
                        <div className="restaurant-search-handoff-options">
                            <HandoffToggle
                                onHandoffToggle={(option: HandoffOptions) => dispatch(toggleOrderHandoff(option))}
                            />
                        </div>
                    </div>
                        }

                        {!isOnMobile && props.resultsWithoutFiltersCounter > 0 &&
                            <div className='restaurants-search-top-bar_main_search'>
                                <RestaurantSearchTextInput
                                    restaurantFilters={props.restaurantFilters}
                                    updateRestaurantFilters={props.updateRestaurantFilters}
                                />
                            </div>
                        }
                    </div>
                    <div className="restaurants-search-top-bar_account">
                        <DevourTopNavUserData onShowSignInToast={() => setShowToast(true)} dropdownAnchorElement=".restaurants-search-top-bar" hideWalletOnMobile={true}/>
                    </div>
                </div>

                {isOnMobile && <GoVipRestaurantSearchBanner />}

                {isOnMobile && props.resultsWithoutFiltersCounter > 0 &&
                    <div className='restaurants-search-top-bar_mobile-search'>
                        <RestaurantSearchTextInputMobile
                            restaurantFilters={props.restaurantFilters}
                            updateRestaurantFilters={props.updateRestaurantFilters}
                        />
                    </div>
                }
            </div>

            {!isOnMobile && <GoVipRestaurantSearchBanner />}
        </>
    );
}

export default RestaurantSearchTopBar;
