import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {isMobile} from "react-device-detect";
import ProgressBar from "@ramonak/react-progress-bar";
import {ReactElement, useEffect} from "react";
import {useGetUserLevel} from "@/hooks/useGetUserLevel";
import {updateAccountLevel} from "@/redux/meta/metaActions";

export default function OverwolfGoVipAccountLevel() {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const {data: userProfile} = useGetUserProfile(fullToken);
    const {data: userLevelData} = useGetUserLevel(fullToken, currentUser?.user?.id);

    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const userLevel = accountLevel?.level ?? 1;

    useEffect(() => {
        if (userLevelData) {
            dispatch(updateAccountLevel(userLevelData));
        }
    }, [userLevelData]);

    function generatePointText(): ReactElement {
        if (!fullToken || !accountLevel) {
            return <>1,000 XP{isMobile && <br />} to level 2</>;
        }

        if (accountLevel.userRequiresIndustryCollectionNftToLevelUp && !accountLevel.userOwnsIndustryCollectionNft &&
            accountLevel.experienceRequiredForNextLevel <= 0) {
            return <>Get Industry{isMobile && <br />} Pass to level up</>;
        }

        const experienceRequired: string = accountLevel.experienceRequiredForNextLevel.toLocaleString();
        return <>{experienceRequired} XP{isMobile && <br />} to level {userLevel + 1}</>;
    }

    return (
        <div className="overwolf-govip_xp">
            <div className="overwolf-govip_xp_profile-pic">
                {fullToken
                    ? <>
                        {userProfile?.user?.gravatar &&
                            <img
                                src={userProfile.user.gravatar}
                                alt="User Profile"
                            />
                        }
                    </>
                    : <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/devourgo-profile.webp`}
                        alt="User Profile"
                    />
                }
            </div>

            <div className="overwolf-govip_xp_content">
                <div className="overwolf-govip_xp_counter">
                    <div className="overwolf-govip_xp_counter_number">
                        <b>{fullToken ? accountLevel?.experienceGainedAtThisLevel : 0}</b> XP
                    </div>
                    <div className="overwolf-govip_xp_counter_next-lvl">
                        <span>{generatePointText()}</span>
                        {/* <IoChevronForward size="1.125rem"/>*/} {/* For 1.0 */}
                    </div>
                </div>

                <div className="overwolf-govip_xp_bar">
                    <span>{isMobile ? "lvl" : "Level"} {userLevel}</span>
                    <ProgressBar
                        height="14px"
                        bgColor={"linear-gradient(255deg, #DED6F8 34.51%, #AA96EF 70.22%)"}
                        baseBgColor={"#212123"}
                        className="overwolf-govip_xp_bar_bar"
                        isLabelVisible={false}
                        completed={fullToken && accountLevel
                            ? accountLevel.experienceGainedAtThisLevel
                            : 0}
                        maxCompleted={fullToken && accountLevel
                            ? accountLevel.experienceGainedAtThisLevel +
                            accountLevel.experienceRequiredForNextLevel
                            : 0}
                    />
                </div>
            </div>
        </div>
    );
}