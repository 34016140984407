import {isMobile} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import OverwolfGoVipAccountLevel from "@/pages/overwolf/components/OverwolfGoVipAccountLevel";
import OverwolfGoVipTimeRewards from "@/pages/overwolf/components/OverwolfGoVipTimeRewards";
import FrameButton from "@/components/buttons/FrameButton";
import {useEffect, useState} from "react";
import MagicLoginModal from "@/components/modals/MagicLoginModal";
import {toggleSidebar} from "@/redux/meta/metaActions";

export default function OverwolfGoVip() {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const dispatch = useDispatch();

    // Close the sidebar menu if open
    useEffect(() => {
        dispatch(toggleSidebar(false));
    }, []);

    return (
        <div
            className="overwolf-govip"
            style={{
                backgroundImage: `url(${`${import.meta.env.VITE_CDN_URL}/images/ow-time-govip-bg${isMobile ? "-sm" : ""}.webp`})`,
            }}
        >
            <MagicLoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} />

            <div className="overwolf-govip_content">
                <div className="overwolf-govip_header">
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/DevourGO+LogoWhite.webp`}
                        alt="Devour logo"
                    />
                    <h1>PLAY TO EARN</h1>
                </div>

                <OverwolfGoVipAccountLevel />

                <div className="overwolf-govip_main">
                    <OverwolfGoVipTimeRewards />
                </div>
            </div>

            {!fullToken && <FrameButton
                size="large"
                className="overwolf-govip_login-btn"
                color="purple-blue-gradient-shadow"
                onClick={() => setIsLoginModalOpen(true)}
            >
                Login or Sign up
            </FrameButton>
            }
        </div>
    );
}