/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    OWLogCategory,
    OWLogCategoryFromJSON,
    OWLogCategoryFromJSONTyped,
    OWLogCategoryToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection contains the user\'s reward stats/progress.
 * @export
 * @interface OWUserStats
 */
export interface OWUserStats {
    /**
     * The ID of the user for which this reward is applicable.
     * @type {string}
     * @memberof OWUserStats
     */
    user_id: string;
    /**
     * The ID of the game for which this reward is applicable.
     * @type {string}
     * @memberof OWUserStats
     */
    game_id: string;
    /**
     * The ID of the reward from the OWRewards collection.
     * @type {string}
     * @memberof OWUserStats
     */
    reward_id: string;
    /**
     * 
     * @type {OWLogCategory}
     * @memberof OWUserStats
     */
    reward_category: OWLogCategory;
    /**
     * The progress towards the target.
     * @type {number}
     * @memberof OWUserStats
     */
    progress: number;
    /**
     * The target to reach to claim the reward.
     * @type {number}
     * @memberof OWUserStats
     */
    target: number;
    /**
     * The UNIX timestamp of the start of the week (Mon 12:01AM EST)
     * @type {number}
     * @memberof OWUserStats
     */
    weekStart: number;
    /**
     * The progress towards the target for the current week.
     * @type {number}
     * @memberof OWUserStats
     */
    weeklyProgress: number;
    /**
     * The timestamp when the event was created.
     * @type {Date}
     * @memberof OWUserStats
     */
    createdAt: Date;
    /**
     * The timestamp when the event was last updated.
     * @type {Date}
     * @memberof OWUserStats
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof OWUserStats
     */
    id: string;
}

export function OWUserStatsFromJSON(json: any): OWUserStats {
    return OWUserStatsFromJSONTyped(json, false);
}

export function OWUserStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWUserStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_id': json['user_id'],
        'game_id': json['game_id'],
        'reward_id': json['reward_id'],
        'reward_category': OWLogCategoryFromJSON(json['reward_category']),
        'progress': json['progress'],
        'target': json['target'],
        'weekStart': json['weekStart'],
        'weeklyProgress': json['weeklyProgress'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'id': json['id'],
    };
}

export function OWUserStatsToJSON(value?: OWUserStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.user_id,
        'game_id': value.game_id,
        'reward_id': value.reward_id,
        'reward_category': OWLogCategoryToJSON(value.reward_category),
        'progress': value.progress,
        'target': value.target,
        'weekStart': value.weekStart,
        'weeklyProgress': value.weeklyProgress,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
    };
}


