import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import classNames from "classnames";
import {FiX} from "react-icons/fi";
import FrameButton from "@/components/buttons/FrameButton";
import {isMobile} from "react-device-detect";


interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export default function OWHotkeyModal(props: Props) {

    function getHotKeyImage() {
        return isMobile ? `${import.meta.env.VITE_CDN_URL}/images/OW-hotkey-sm.webp` : `${import.meta.env.VITE_CDN_URL}/images/OW-hotkey-lg.webp`;
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName={classNames("go-vip-ow_rewards-modal")}
            size="xs"
            modalOnMobile={true}
        >
            <button className="reset-button overwolf-hotkey-modal_close">
                <FiX
                    size={"1.5rem"}
                    strokeWidth="0.175rem"
                    onClick={props.onClose}
                />
            </button>

            <div
                className="overwolf-hotkey-modal"
            >
                <div className="overwolf-hotkey-modal_caption">
                    <div className="overwolf-hotkey-modal_caption_line1">
                        <span>Press</span>
                        <img className="hotkey alt" src={`${import.meta.env.VITE_CDN_URL}/images/OW-alt.webp`}
                            alt="Overwolf Alt Key"/>
                        <span className="plus">+</span>
                        <img className="hotkey" src={`${import.meta.env.VITE_CDN_URL}/images/OW-D.webp`}
                            alt="Overwolf D Key"/>
                    </div>

                    <div className="overwolf-hotkey-modal_caption_line2">
                        <span>to open DevourGO+ <br/> overlay while playing!</span>
                    </div>
                </div>

                <div className="overwolf-hotkey-modal_img"
                    style={{
                        backgroundImage: `url(${getHotKeyImage()})`,
                    }}>
                </div>

                <FrameButton
                    size="normal"
                    className="overwolf-hotkey-modal_btn"
                    color="purple"
                    onClick={props.onClose}
                >
                    Got it!
                </FrameButton>
            </div>
        </FrameOneAutoPanel>
    );
}