import React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./redux";
import App from "./App";
import "./style/index.scss";
import GA4React from "ga-4-react";

import {WagmiConfig} from "wagmi";
import {createWeb3Modal} from "@web3modal/wagmi/react";
import {defaultWagmiConfig} from "@web3modal/wagmi/react/config";
import {arbitrum, base, mainnet, polygon} from "viem/chains";
import {initSentry} from "./utils/sentry";
import initHotjar from "@/utils/hotjar";

const ga4react = new GA4React(
    import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_CODE,
    {
        debug_mode: process.env.NODE_ENV === "development",
    },
    [],
    5000 /* timeout, optional, defaults is 5000 */,
    {}, /* { nonce: ['first-script-is-async','second-script'] } */
);

const projectId = "726737766a01cd47d7e79d8a3352e859";
const chains = [
    arbitrum,
    mainnet,
    base,
    polygon,
];

const metadata = {
    name: "DevourGo",
    description: "DevourGo",
    url: "https://devourgo.io",
    icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const wagmiConfig = defaultWagmiConfig({chains,
    projectId,
    metadata});

createWeb3Modal({wagmiConfig,
    projectId,
    chains});

initSentry();

initHotjar();
(async () => {

    try {
        await Promise.all([ga4react.initialize()]);
    } catch (err) {
        console.error("GA init error", err);
    }
    createRoot(container!).render(<React.Fragment>
        <Provider store={store}>
            <PersistGate
                persistor={persistor}
                loading={null}
            >
                <WagmiConfig config={wagmiConfig}>
                    <App/>
                </WagmiConfig>

            </PersistGate>
        </Provider>
    </React.Fragment>);
})();
const container = document.getElementById("root");
