import {useMutation, useQueryClient} from "@tanstack/react-query";
import {
    MenuOrder,
    MenuOrderItem,
} from "@devour/client";
import {
    addDevourIqMenuOrderError,
    addMenuOrder,
    addMenuOrderError,
    makeApiError,
    removeDpayPriceExpiryTime,
    toggleMenuOrderUpdating,
} from "@/redux/meta/metaActions";
import {useDispatch} from "react-redux";
import {RecreateMenuOrderResult, updateMenuOrder} from "@/utils/updateMenuOrder";

interface UpdateMenuOrderItemsFormInit {
    menuOrder: MenuOrder;
    menuOrderErrorModal?: boolean;
    backgroundCallback?: boolean;
}

interface UpdateMenuOrderItemsFormValues {
    orderItems: Array<MenuOrderItem>;
}

/**
 * Update the menu order's food items
 *
 * @param options
 */
export function useUpdateMenuOrderItems(options: UpdateMenuOrderItemsFormInit) {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (formValues: UpdateMenuOrderItemsFormValues) => {
            dispatch(toggleMenuOrderUpdating(true));
            const updateResults: RecreateMenuOrderResult = await updateMenuOrder({
                menuOrder: options.menuOrder,
                backgroundCallback: options.backgroundCallback,
                createMenuOrderBody: {
                    orderItems: formValues.orderItems,
                },
            });
            dispatch(toggleMenuOrderUpdating(false));

            if (updateResults?.error) {
                const error = await makeApiError(updateResults.error);
                if (!options.menuOrderErrorModal) {
                    throw error;
                }
                dispatch(addMenuOrderError({
                    errorMessage: error?.message,
                    restaurantId: options.menuOrder?.business,
                }));
                await queryClient.invalidateQueries({queryKey: ["restaurant-menu", options.menuOrder?.business]});
                return;
            }

            if (updateResults?.devourIqNotification) {
                dispatch(addDevourIqMenuOrderError({
                    errorMessage: updateResults.devourIqNotification,
                    restaurantId: options.menuOrder?.business,
                }));
                await queryClient.invalidateQueries({queryKey: ["restaurant-menu", options.menuOrder?.business]});
            }

            if (updateResults?.isNew) {
                dispatch(addMenuOrder(options.menuOrder?.business, updateResults.menuOrder?.id));
            }

            // dispatch(refreshMenuOrderCart());
            dispatch(removeDpayPriceExpiryTime());

            /*
             * Update the menuOrder data cache with the new updated menu order
             * No need refetchMenuOrder. This eliminates the need to
             * refetch the menu order from the database, increasing speed
             */
            queryClient.setQueryData([
                "menuOrder",
                updateResults.menuOrder?.id,
            ], updateResults.menuOrder);
            return updateResults.menuOrder;
        },
        onError: (err) => {
            if (options.menuOrderErrorModal) {
                dispatch(addMenuOrderError({
                    errorMessage: err?.message,
                    restaurantId: options.menuOrder?.business,
                }));
            } else {
                throw err;
            }
        },
    });
}