import FrameButton from "@/components/buttons/FrameButton";
import MagicLoginModal from "@/components/modals/MagicLoginModal";
import { useOnLogin } from "@/hooks/useOnLogin";
import { store } from "@/redux";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import OWHotkeyModal from "@/components/overwolf/OWHotkeyModal";


const OverwolfLandingPage = () => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isHotkeyModalOpen, setIsHotkeyModalOpen] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        if (store.getState().authStore.fullToken) {
            navigate("/overwolf-go-vip");
        }
    }, []);

    useOnLogin(() => {
        navigate("/overwolf-go-vip");
    });
    const getBackground = () => {
        const profix = isMobile ? "Sm" : "Lg";
        return `url(${`${import.meta.env.VITE_CDN_URL}/images/Time-Based-Game-Landing-${profix}.webp`})`;
    };

    const renderTitleBreak = () => {
        return isMobile ? <br /> : <>{" "}</>;
    };

    return (
        <div className="overwolf-landing-page" style={{
            backgroundImage: getBackground(),
        }} >
            <OWHotkeyModal isOpen={isHotkeyModalOpen} onClose={() => setIsHotkeyModalOpen(false)}/>
            <MagicLoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} />

            <div className="overwolf-landing-page_content" >
                <div className="overwolf-landing-page_content_logo">
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/DevourGO+LogoWhite.webp`}
                        alt="Devour logo"
                    />
                </div>
                <div className="overwolf-landing-page_content_info">
                    <span className="overwolf-landing-page_content_info_title">EAT.{renderTitleBreak()}PLAY.{renderTitleBreak()}EARN.</span>
                    <p className="overwolf-landing-page_content_info_description">
                        {isMobile ? <>Complete special quests in games to unlock DevourGO+ GoVIP XP. <br/> Jump in now and earn!</>
                            : <>Complete special quests in games to unlock DevourGO+ GoVIP XP. Jump in now and power up your rewards!</>}
                    </p>
                </div>

                <div className="overwolf-landing-page_content_actions">
                    <FrameButton size="narrow" color="purple-blue-gradient-shadow" onClick={() => setIsLoginModalOpen(true)} >Login or Sign up</FrameButton>
                    <FrameButton size="narrow" color="white-outline" onClick={() => navigate("/overwolf-go-vip")}>Explore</FrameButton>
                </div>
            </div>

        </div>
    );
};

export default OverwolfLandingPage;
