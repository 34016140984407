import {ReactElement, useContext, useEffect, useRef, useState} from "react";
import {
    GetMenuResponse,
    MenuItem,
    PreviousMenuOrderItem,
} from "@devour/client";
import {useParams} from "react-router";
import {useRestaurantPreviousOrderedItems} from "@/hooks/useRestaurantPrevouslyOrderedItems";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {findMenuItemResponse} from "@/utils/validateMenuOrderItem";
import OrderAgainCard from "@/pages/restaurants/components/OrderAgainCard";
import classNames from "classnames";
import FrameButton from "@/components/buttons/FrameButton";
import {FiArrowLeft, FiArrowRight} from "react-icons/fi";
import Divider from "@/components/Divider";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {isMobile} from "react-device-detect";
import DragScroll from "@/components/DragScroll";

interface Props {
    restaurantMenu: GetMenuResponse;
    searchValue?: string;
}

function RestaurantMenusOrderAgain(props: Props): ReactElement {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {restaurantId} = useContext(RestaurantContext);
    const { placeId} = useParams<{ placeId: string; }>();
    const queryRestaurantPreviousOrderedItems = useRestaurantPreviousOrderedItems(restaurantId, fullToken);
    const filteredMenuItems = queryRestaurantPreviousOrderedItems.data?.orderItems.filter(filterMenuOrderItem);
    const btnListRef = useRef<HTMLUListElement>(null);
    const [leftOffset, setLeftOffset] = useState({
        left: 0,
        max: false,
    });
    const isOnOverwolf = useSelector((store: IStore) => store.metaStore.isOnOverwolf);

    /**
     * We run the scroll function once on mount just to check the total width of the container, so we know if we need
     * to show the "right" arrow.
     */
    useEffect(() => {
        if (btnListRef.current) {
            slideCards(true)();
        }
    }, [queryRestaurantPreviousOrderedItems.data]);

    useEffect(() => {
        if (btnListRef.current) {
            btnListRef.current.scroll({
                left: leftOffset.left,
                behavior: "smooth",
            });
        }
    }, [leftOffset]);

    /**
     * Scroll the categories section by a certain amount when clicking the arrows.
     * On smaller screens, just scroll by 100px, and larger screens scroll 50% of the visible width of the section.
     * This implementation isn't perfect as it doesn't account for screen resizing to know if we need to show
     * the "right" button; as we try to determine that on initial render.
     *
     * @param left
     */
    function slideCards(left: boolean): () => void {
        return () => {
            const scrollAmountPercentage = btnListRef.current?.clientWidth * 0.5;
            let scrollAmountCalculated = scrollAmountPercentage > 100
                ? scrollAmountPercentage
                : 100;

            if (!left && leftOffset.left + scrollAmountCalculated >= btnListRef?.current?.scrollWidth - btnListRef?.current?.clientWidth) {
                scrollAmountCalculated = btnListRef?.current?.scrollWidth - btnListRef?.current?.clientWidth;
                setLeftOffset({
                    left: scrollAmountCalculated,
                    max: true,
                });
            } else {
                setLeftOffset({
                    left: btnListRef.current.scrollLeft + (left
                        ? scrollAmountCalculated * -1
                        : scrollAmountCalculated),
                    max: btnListRef.current.scrollWidth <= btnListRef.current.clientWidth,
                });
            }
        };
    }

    function filterMenuOrderItem(menuOrderItem: PreviousMenuOrderItem): boolean {
        const menuItem = findMenuItemResponse(menuOrderItem, props.restaurantMenu?.menus);
        return menuItem && filterItem(menuItem, props.searchValue);
    }

    function renderMenuItem(menuOrderItem: PreviousMenuOrderItem, index: number): ReactElement {
        return (
            <li
                key={`reorder-item-${index}`}
                className="restaurant-menus-browse_order-again_list_item"
            >
                <OrderAgainCard
                    restaurantMenu={props.restaurantMenu}
                    placeId={placeId}
                    menuOrderItem={menuOrderItem}
                />
            </li>
        );
    }

    if (!filteredMenuItems?.length) {
        return null;
    }

    return (
        <>
            <div
                id="menu-category-order-again"
                className="restaurant-menus-browse_order-again restaurant-page_section-margin"
            >
                <h2>
                    Order Again
                </h2>

                <div className="restaurant-menus-browse_order-again_wrapper">

                    {(isOnOverwolf && isMobile)
                        ? <DragScroll className="restaurant-menus-browse_order-again_list">
                            {filteredMenuItems.map(renderMenuItem)}
                        </DragScroll>
                        : <ul
                            ref={btnListRef}
                            className="restaurant-menus-browse_order-again_list"
                        >
                            {filteredMenuItems.map(renderMenuItem)}
                        </ul>
                    }

                    <div className="restaurant-menus-browse_order-again_arrows">
                        <div
                            className={classNames("restaurant-menus-browse_order-again_arrows_left", {
                                "restaurant-menus-browse_order-again_arrows_left_hidden": !(leftOffset.left > 0),
                            })}
                        >
                            <FrameButton
                                color="purple-outline"
                                size="icon"
                                leftIcon={FiArrowLeft}
                                onClick={slideCards(true)}
                            />
                        </div>

                        <div
                            className={classNames("restaurant-menus-browse_order-again_arrows_right", {
                                "restaurant-menus-browse_order-again_arrows_right_hidden": leftOffset.max,
                            })}
                        >
                            <FrameButton
                                color="purple-outline"
                                size="icon"
                                leftIcon={FiArrowRight}
                                onClick={slideCards(false)}
                            />
                        </div>
                    </div>

                </div>
            </div>
            <Divider />
        </>
    );
}

function filterItem(item: MenuItem, search: string): boolean {
    if (!item.isEnabled) {
        return false;
    }
    return item?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
        item.description?.toLowerCase()?.includes(search.toLowerCase());
}

export default RestaurantMenusOrderAgain;
