import {useQuery} from "@tanstack/react-query";
import {AccountLevel, Token, UsersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchUserLevel(fullToken: Token, userId: string): Promise<AccountLevel> {
    if (!userId || !fullToken) return;

    return await new UsersApi(getConfig(fullToken)).getLevel({
        id: userId,
    });
}

function useUserLevelQuery(fullToken: Token, userId: string) {
    return {
        queryKey: [
            "user-level",
            fullToken?.id ?? "unauthenticated",
            userId ?? "",
        ],
        queryFn: () => fullToken && userId ? fetchUserLevel(fullToken, userId) : null,
        enabled: !!fullToken && !!userId,
    } as const;
}

export function useGetUserLevel(fullToken: Token, userId: string) {
    return useQuery(useUserLevelQuery(fullToken, userId));
}