import { OrderTrackerData, OrderTrackerDataFullfillmentStatus } from "@/types/OrderTrackerData";
import { getFakeTrackerData } from "@/utils/getFakeTrackerData";
import { isDev } from "@/utils/isDev";
import { MenuOrder, Restaurant } from "@devour/client";
import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";

interface debugData {
    order: MenuOrder,
    restaurant: Restaurant,
    status: OrderTrackerDataFullfillmentStatus
}

export const menuOrderTrackerQueryKey = "menu-order-tracking";

export const useMenuOrderTracker = (menuOrderId: string, debugData?: debugData) => {
    const result = useQuery({
        queryKey: [
            menuOrderTrackerQueryKey,
            menuOrderId,
        ],
        queryFn: async () => {
            if (!menuOrderId) {
                return null;
            }
            return await new Promise<OrderTrackerData | null>((resolve) => {
                if (isDev() && debugData) {
                    resolve(getFakeTrackerData(debugData.order, debugData.status, debugData.restaurant));
                    return;
                }
                fetch(`https://api.firstdelivery.com/orders/${menuOrderId}/status`).then(async (res) => {
                    resolve(await res.json());
                })
                    .catch(() => {
                        resolve(null);
                    });
            });
        },
        enabled: Boolean(menuOrderId), // Conditional fetching based on menuOrderId presence
    });

    return {
        data: result.data,
        refetch: result.refetch,
        isLoading: result.isLoading,
    };
};
