import {AccountExperienceTransaction, GetOverwolfXpSubjectEnum} from "@devour/client";
import GoVipOWTimeBasedRewardCard from "@/components/goVip/GoVipOWTimeBasedRewardCard";
import {isDesktop} from "react-device-detect";
import useGetUserOverwolfXP from "@/hooks/useGetUserOverwolfXP";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import Skeleton from "react-loading-skeleton";
import Tooltip from "@/components/Tooltip";
import {BsFillQuestionCircleFill} from "react-icons/bs";
import {useEffect, useState} from "react";
import classNames from "classnames";
import {capitalizeFirstLetter} from "@/utils/formatString/capitalizeFirstLetter";

export default function OverwolfGoVipTimeRewards() {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {
        data: rewardsData,
        isLoading: isRewardsDataInitialLoading,
    } = useGetUserOverwolfXP(fullToken, GetOverwolfXpSubjectEnum.TIMEREWARD);
    const [gameDetectionTag, setGameDetectionTag] = useState("Game not detected");
    const [isGameDetected, setIsGameDetected] = useState(false);
    const gameInfo = useSelector((store: IStore) => store.metaStore.overwolfGameInfo);

    useEffect(() => {
        console.log("devourGO iframe game info: ", gameInfo);
        if (gameInfo) {
            setGameDetectionTag(gameInfo.gameTitle);
            setIsGameDetected(true);
        } else {
            setGameDetectionTag("Game not detected");
            setIsGameDetected(false);
        }
    }, [gameInfo]);

    const renderSkeleton = () =>
        <div className="overwolf-govip_time-rewards_list">
            {Array.from({length: 5}, (_, i) =>
                <div key={i} className="go-vip-ow_time-reward-card overwolf-card">
                    <div>
                        <p><Skeleton width={150}/></p>
                        <span><Skeleton width={110}/></span>
                    </div>
                    <span><Skeleton width={110}/></span>
                </div>)}
        </div>;

    return (
        <div className="overwolf-govip_time-rewards">
            <div className="overwolf-govip_time-rewards_header">
                <span className={classNames("overwolf-govip_time-rewards_tag", {
                    "overwolf-govip_time-rewards_tag_active": isGameDetected,
                })}
                >
                    <div className={classNames("circle", {
                        "circle_active": isGameDetected,
                        "circle_inactive": !isGameDetected,
                    })}
                    />
                    {isGameDetected && "In "} {capitalizeFirstLetter(gameDetectionTag)}
                </span>
                <div className="overwolf-govip_time-rewards_header_top">
                    <h4>Time Rewards</h4>
                    <Tooltip
                        className="overwolf-govip_time-rewards_header_tooltip"
                        outsideIcon={BsFillQuestionCircleFill}
                        containerElementClassname="overwolf-govip_time-rewards"
                    >
                        <div className="overwolf-govip_time-rewards_header_tooltip_content">
                            Earn up to max 1000 XP each week! Your weekly XP limit resets every Monday at 12:00 AM
                            (EST).
                        </div>
                    </Tooltip>
                </div>

                <div className="overwolf-govip_time-rewards_header_description">
                    {!fullToken && "Earn 10 XP for every hour you play, with a max of 1000 XP each week!"}
                    {fullToken && `Earn 10 XP for every hour of gameplay! ${isDesktop ? "The more you play, the more XP you collect." : ""}`}
                </div>
            </div>

            {isRewardsDataInitialLoading
                ? renderSkeleton()
                : <>
                    {!rewardsData?.length
                        ? <div className="overwolf-govip_time-rewards_empty">
                            <img
                                src={`${import.meta.env.VITE_CDN_URL}/images/govip-ow-time-rewards-modal-empty.webp`}
                                alt="Overwolf Time Rewards Empty"
                            />
                            <h5>Haven’t played enough?</h5>
                            <span>
                                No XP yet, but the more you play, the more you earn. {isDesktop && <br/>}Jump into a game and play for an hour to earn your first XP!
                            </span>
                        </div>
                        : <div className="overwolf-govip_time-rewards_list">
                            {rewardsData.map((reward: AccountExperienceTransaction) =>
                                <GoVipOWTimeBasedRewardCard
                                    className="overlay-card"
                                    reward={reward}
                                    key={reward.id}/>)}
                        </div>
                    }
                </>
            }

        </div>
    );
}