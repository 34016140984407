import {AnchorHTMLAttributes, ButtonHTMLAttributes, ReactElement, useEffect, useState} from "react";
import RestaurantInitialAddressSearch
    from "@/pages/restaurants/components/RestaurantInitialAddressSearch";
import {MdOutlineKeyboardDoubleArrowDown} from "react-icons/md";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import {useSelector, useDispatch } from "react-redux";
import {IStore} from "@/redux/defaultStore";
import FrameButton from "@/components/buttons/FrameButton";
import {restaurantSearchRedirect} from "@/utils/restaurantSearchRedirect";
import {LinkProps, useNavigate} from "react-router-dom";
import LandingIndustryBenefitsRow from "@/components/LandingIndustryBenefitsRow";
import useThemePreference from "@/hooks/useThemePreference";
import { ThemePreference } from "@/types/Theme";
import { toggleLoginOpen } from "@/redux/meta/metaActions";
import { useOnLogin } from "@/hooks/useOnLogin";
import { store } from "@/redux";
import OnboardingModal from "@/components/modals/onboardingModal/OnboardingModal";
import {ReactComponent as HowItWorksArrow} from "../svgs/how-it-works-arrow.svg";
import {useGate} from "statsig-react";
import useTabIsClosing from "@/hooks/useTabIsClosing";
import useInactive from "@/hooks/useInactive";
import getAnonymousSpin from "@/utils/getAnonymousSpin";
import GoVipSpin2WinModal, { AnonymousSpinModalShownKey } from "@/components/modals/GoVipSpin2WinModal";


function Landing(): ReactElement {
    const [onboardingOpen, setOnboardingOpen] = useState(false);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);
    const [
        mounted,
        setMounted,
    ] = useState(false);
    const [spinWheelPromptOpen, setSpinWheelPromptOpen] = useState(false);
    const history = useNavigate();
    const dispatch = useDispatch();
    const { isOnDarkMode, setTheme } = useThemePreference();

    const industryBenefits: Array<string> = [
        "Free Delivery",
        ...dpayStatus ? [`$120 in Annual ${import.meta.env.VITE_TOKEN_NAME} rewards`] : [],
        "Access to GoVIP Level 4",
        ...dpayStatus ? [`5% ${import.meta.env.VITE_TOKEN_NAME} Back on DevourGO orders`] : [],
        "Exclusive Premium Promotions",
    ];

    const handleOpenSpinWheelPrompt = () => {
        const fullToken = store.getState().authStore.fullToken;
        const spinModalShown = window[AnonymousSpinModalShownKey];
        const todayAnonymousSpin = getAnonymousSpin();
        if (!fullToken && !spinModalShown && !todayAnonymousSpin) {
            setSpinWheelPromptOpen(true);
        }
    };


    const handleCloseSpinWheelPrompt = () => {
        window[AnonymousSpinModalShownKey] = true;
        setSpinWheelPromptOpen(false);
    };

    useTabIsClosing(() => {
        if (!isMobile) {
            handleOpenSpinWheelPrompt();
        }
    });

    useInactive(() => {
        if (isMobile) {
            handleOpenSpinWheelPrompt();
        }
    });

    useEffect(() => {
        checkIfRedirect();
        setTheme(ThemePreference.DARK, false);
    }, []);

    useOnLogin(() => {
        checkIfRedirect();
    });

    async function checkIfRedirect(): Promise<void> {
        // Auto redirect to restaurant search location if route is "/" and user is logged in
        const currUser = store.getState().metaStore.currentUser;
        if (currUser) {
            const redirectRoute = await restaurantSearchRedirect(currUser?.user?.addresses, lastSearchedPlaceId);
            if (redirectRoute) {
                history(redirectRoute);
            }
        }
        setMounted(true);
    }

    function getImagePrefix() {
        let prefix = "lg";
        if (isMobile) {
            prefix = "sm";
        } else if (isTablet) {
            prefix = "md";
        }
        return prefix;
    }

    function getDevourTextureImage() {
        return `${import.meta.env.VITE_CDN_URL}/images/devourgo-texture-${getImagePrefix()}.webp`;
    }

    function getHeroBackgroundImage() {
        return `${import.meta.env.VITE_CDN_URL}/images/lp-FUEL-header-bg-${getImagePrefix()}.webp`;
    }

    function renderGoVipGraphics() {
        return (
            <div
                className="landing_go-vip_contents_graphics"
                style={{backgroundImage: `url(${`${import.meta.env.VITE_CDN_URL}/images/landing-govip.webp`})`}}
            >
                <img
                    src={`${import.meta.env.VITE_CDN_URL}/images/landing-level.webp`}
                    className="landing_go-vip_contents_graphics_level"
                    alt="level"
                />
                <img
                    src={`${import.meta.env.VITE_CDN_URL}/images/landing-leaderboard.webp`}
                    className="landing_go-vip_contents_graphics_leaderboard"
                    alt="leaderboards"
                />
            </div>
        );
    }

    function renderDeliveryGraphics() {
        return (
            <div
                style={{backgroundImage: `url(${`${import.meta.env.VITE_CDN_URL}/images/landing-delivery.webp`})`}}
                className="landing_delivery_contents_graphics"
            >
                <img
                    src={`${import.meta.env.VITE_CDN_URL}/images/landing-restaurant.webp`}
                    alt="level"
                    className="landing_delivery_contents_graphics_restaurant"
                />
                <img
                    src={`${import.meta.env.VITE_CDN_URL}/images/landing-checkout.webp`}
                    alt="level"
                    className="landing_delivery_contents_graphics_checkout"
                />

            </div>
        );
    }

    function renderAppGraphics() {
        return (
            <img
                src={`${import.meta.env.VITE_CDN_URL}/images/GetApp-Mock-${getImagePrefix()}.webp`}
                className="landing_app_graphic"
                alt="devour app"
            />
        );
    }

    function renderReferGraphics() {
        return (
            <div
                style={{backgroundImage: `url(${`${import.meta.env.VITE_CDN_URL}/images/landing-refer.webp`})`}}
                className="landing_refer_graphics"
            />
        );
    }

    function renderIndustryBenefits() {
        return industryBenefits.map((message) => <LandingIndustryBenefitsRow message={message} key={message}/>);
    }

    // to prevent flickering while we check the location status
    if (!mounted) {
        return <div className="landing_loading"></div>;
    }

    return (
        <>

            <GoVipSpin2WinModal
                anonymousPrompt={true}
                isOpen={spinWheelPromptOpen}
                toggle={handleCloseSpinWheelPrompt}
            />
            <div className="landing">
                <OnboardingModal
                    isOpen={onboardingOpen}
                    toggle={(openLogin) => {
                        setOnboardingOpen(false);
                        if (openLogin) {
                            dispatch(toggleLoginOpen(true));
                        }
                    }}/>
                {/* TOP NAVIGATION*/}
                <div className="landing_top-header">
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/DM-DevourGO_Final-01.webp`}
                        alt="Restaurant Search Background"
                        className="landing_top-header_logo"
                    />
                    <FrameButton
                        color="white-drop-shadow"
                        size={isDesktop || isTablet
                            ? "narrow"
                            : "pill"}
                        onClick={() => {
                            if (fullToken) {
                                history("/go-vip");
                            } else {
                                dispatch(toggleLoginOpen(true));
                            }
                        }}
                    >
                        {fullToken
                            ? "Go to GoVIP"
                            : "Login or Sign Up"}
                    </FrameButton>

                </div>

                {/* HERO SECTION*/}
                <div
                    className="landing_hero"
                >
                    <div className="landing_hero_content"
												 style={{backgroundImage: `url(${getHeroBackgroundImage()})`}}>
                        <h1 className="landing_hero_slogan">
														EAT.
                        </h1>
                        <h1 className="landing_hero_slogan">
														PLAY.
                        </h1>
                        <h1>
                            <span className="landing_hero_highlight">
                            EARN.
                            </span>
                        </h1>
                        <div className="landing_hero_onboarding-trigger" onClick={() => setOnboardingOpen(true)}>
                            <h3 className="landing_hero_gradient-text">See How It Works</h3>
                            <HowItWorksArrow />
                        </div>
                        <RestaurantInitialAddressSearch/>
                    </div>
                    <div className="landing_hero_scroll-content" style={{
                        backgroundImage: `url(${getDevourTextureImage()})`,
                    }}>
                        <button
                            className="reset-button landing_hero_scroll-container"
                            // onClick={() => window.scroll(0, window.document.documentElement.clientHeight)}
                            onClick={() => window.scroll({
                                behavior: "smooth",
                                left: 0,
                                top: window.document.documentElement.clientHeight,
                            })}
                        >
                            <MdOutlineKeyboardDoubleArrowDown
                                className="landing_hero_scroll-container_arrow"/>
                        </button>
                    </div>

                </div>

                {/* JUMP IN - SECTION AFTER SCROLL*/}
                <div className="landing_jump-in">
                    <h1 className="landing_hero_slogan">
												JUMP IN.
                    </h1>
                    <p>
												Devour is on a mission to make ordering food a convenient and rewarding
												experience for you while
												you play.
                    </p>
                </div>

                {/* GOVIP SECTION*/}
                <div className="landing_go-vip">
                    <div className="landing_go-vip_contents">

                        {(isDesktop || isTablet) && renderGoVipGraphics()}

                        <div className="landing_go-vip_contents_info">
                            <p className="landing_go-vip_contents_info_tag">GoVIP</p>
                            <h2 className="landing_go-vip_contents_info_level-up">LEVEL UP TO
																WIN</h2>
                            <p className="landing_go-vip_contents_info_description">Play, share, and
																spin your way to
																amazing rewards sure to satisfy any appetite.</p>
                            <FrameButton
                                color="purple"
                                size="narrow"
                                to="/go-vip"
                            >
																Start Playing
                            </FrameButton>

                        </div>

                        {isMobile && !isTablet && renderGoVipGraphics()}

                        {(isDesktop || isTablet) && !isOnDarkMode &&
														<img
														    src={`${import.meta.env.VITE_CDN_URL}/images/solid-devour-d.webp`}
														    className="landing_go-vip_contents_devour-d"
														    alt="devour"
														/>
                        }
                    </div>
                </div>

                {/* GOFRENS SECTION*/}
                <div className="landing_go-frens">
                    <div className="landing_go-frens_contents">
                        <div className="landing_go-frens_contents_info">
                            <p className="landing_go-vip_contents_info_tag">GoFriends</p>
                            <h2 className="landing_go-vip_contents_info_level-up">REP YOUR
																COMMUNITY</h2>
                            <p className="landing_go-frens_contents_info_description">Score XP,
																and help your
																community
																dominate the leaderboards.</p>
                            <FrameButton
                                color="purple"
                                size="narrow"
                                to="/gofriends"
                            >
                                Join Now
                            </FrameButton>
                        </div>

                        <div
                            style={{backgroundImage: `url(${`${import.meta.env.VITE_CDN_URL}/images/landing-gofrens.webp`})`}}
                            className="landing_go-frens_contents_graphics"
                        >
                            <img
                                src={`${import.meta.env.VITE_CDN_URL}/images/landing-summary.webp`}
                                alt="level"
                                className="landing_go-frens_contents_graphics_summary"
                            />
                        </div>
                    </div>
                </div>

                {/* DELIVERY SECTION*/}
                <div className="landing_delivery">

                    {isDesktop && !isOnDarkMode &&
												<img
												    src={`${import.meta.env.VITE_CDN_URL}/images/outline-devour-d.webp`}
												    className="landing_delivery_devour-d"
												    alt="devour"
												/>
                    }

                    <div className="landing_delivery_contents">
                        {(isDesktop || isTablet) && renderDeliveryGraphics()}

                        <div className="landing_delivery_contents_info">
                            <p className="landing_go-vip_contents_info_tag">Delivery</p>
                            <h2 className="landing_go-vip_contents_info_level-up">FOOD DELIVERY,
																FAST</h2>
                            <p className="landing_go-frens_contents_info_description">Get more from
																your restaurant
																orders,
																delivered to your door!</p>
                            <FrameButton
                                color="purple"
                                size="narrow"
                                onClick={async () => {
                                    const redirectRoute = await restaurantSearchRedirect(currentUser?.user?.addresses, lastSearchedPlaceId);
                                    if (redirectRoute) {
                                        history(redirectRoute);
                                    } else {
                                        window.scroll(0, 0);
                                    }
                                }}
                            >
																Find Restaurants
                            </FrameButton>
                            {isDesktop && !isOnDarkMode &&
																<img
																    src={`${import.meta.env.VITE_CDN_URL}/images/solid-devour-d.webp`}
																    className="landing_delivery_contents_info_devour-d"
																    alt="devour"
																/>
                            }
                        </div>
                        {isMobile && !isTablet && renderDeliveryGraphics()}

                    </div>
                </div>

                {/* APP PROMO SECTION*/}
                <div className="landing_app">
                    {isMobile && !isTablet && renderAppGraphics()}
                    <div className="landing_app_info">
                        <h2 className="landing_app_info_slogan">GET THE <span
                            className="landing_app_info_highlight">APP</span></h2>
                        <p className="landing_app_info_subheader">
                            Download the DevourGO app for the best experience.
                        </p>
                        <div className="landing_app_info_buttons">
                            <a href="https://apps.apple.com/us/app/devourgo-food-delivery/id6448696874">
                                <img
                                    src={`${import.meta.env.VITE_CDN_URL}/images/applestore.webp`}
                                    className="landing_app_info_buttons_button"
                                    alt="app store"
                                />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=io.devourgo.app">
                                <img
                                    src={`${import.meta.env.VITE_CDN_URL}/images/googleplay.webp`}
                                    className="landing_app_info_buttons_button"
                                    alt="google play"
                                />
                            </a>
                        </div>
                    </div>
                    {(isDesktop || isTablet) && renderAppGraphics()}
                </div>

                {/* INDUSTRY PASS SECTION*/}
                <div className="landing_industry-pass">
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/landing-industry-gradient.svg`}
                        className="landing_industry-pass_bg-gradient"
                        alt="gradient"
                    />
                    <div className="landing_industry-pass_contents">
                        <img
                            src={`${import.meta.env.VITE_CDN_URL}/images/landing-industrypass.webp`}
                            className="landing_industry-pass_contents_graphic"
                            alt="industry pass"
                        />
                        <div className="landing_industry-pass_contents_ad">
                            <p className="landing_go-vip_contents_info_tag">The Industry NFT
																Collection</p>
                            <h2 className="landing_go-vip_contents_info_level-up">GET YOUR VIP
																PASS</h2>
                            <p className="landing_go-frens_contents_info_description">
																Get your golden ticket to a world of benefits within the Devour
																ecosystem.
                            </p>

                            <div className="landing_industry-pass_contents_ad_card">
                                <div
                                    className="landing_industry-pass_contents_ad_card_padding-adjust-top">
                                    <h3>GoVIP INDUSTRY PASS</h3>
                                    <p className="landing_industry-pass_contents_ad_card_description">
																				Can be purchased with cryptocurrency or with credit card
                                    </p>
                                    <h3 className="landing_industry-pass_contents_ad_card_price">USD
																				$150</h3>
                                    <p className="landing_industry-pass_contents_ad_card_description">per
																				pass</p>
                                    <FrameButton
                                        to="/the-industry-pass"
                                        color="purple"
                                        size="normal"
                                        className="landing_industry-pass_contents_ad_card_button"
                                    >
																				Get your pass now
                                    </FrameButton>
                                </div>

                                <hr/>

                                <div
                                    className="landing_industry-pass_contents_ad_card_padding-adjust-bottom">
                                    {renderIndustryBenefits()}
                                </div>
                            </div>
                        </div>

                    </div>
                    {dpayStatus &&
												<img
												    src={`${import.meta.env.VITE_CDN_URL}/images/landing-dpay-gradient-top.svg`}
												    className="landing_industry-pass_bottom-gradient"
												    alt="gradient"
												/>
                    }
                </div>

                {/* DPAY BACK SECTION*/}
                {dpayStatus &&
										<div
										    style={{backgroundImage: `url(${`${import.meta.env.VITE_CDN_URL}/images/landing-dpay-gradient-middle.svg`})`}}
										    className="landing_dpay-back"
										>
										    <div className="landing_dpay-back_content">
										        <div className="landing_dpay-back_content_info">
										            <h2 className="landing_dpay-back_content_info_slogan">GET UP TO 10%
										                <span className="landing_dpay-back_content_info_highlight"> {import.meta.env.VITE_TOKEN_NAME} BACK</span>
										            </h2>
										            <p className="landing_dpay-back_content_info_subheader">Get up to
																		10% back in {import.meta.env.VITE_TOKEN_NAME} on every
																		DevourGO order!</p>
										            <p className="landing_dpay-back_content_info_blurb">
																		Holding the Industry Pass gets you 5% and paying with {import.meta.env.VITE_TOKEN_NAME} gets
																		you another 5%.
										            </p>
										            <FrameButton
										                <LinkProps>
										                color="white-drop-shadow"
										                size="narrow"
										                className="landing_dpay-back_content_info_button"
										                to={fullToken
										                    ? "/load-fuel"
										                    : "/log-in"}
										            >
																		Buy {import.meta.env.VITE_TOKEN_NAME} now
										            </FrameButton>
										        </div>
										    </div>
										    <img
										        src={`${import.meta.env.VITE_CDN_URL}/images/landing-bike.webp`}
										        className="landing_dpay-back_content_graphic"
										        alt="bike"
										    />
										</div>
                }

                {/* REFER A FRIEND SECTION*/}
                <div className="landing_refer">
                    {dpayStatus &&
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/landing-dpay-gradient-bottom.svg`}
                        className="landing_dpay-back_bottom-gradient"
                        alt="gradient"
                    />
                    }

                    <div className="landing_refer_contents">

                        {(isDesktop || isTablet) && renderReferGraphics()}

                        <div className="landing_delivery_contents_info">
                            <h2 className="landing_go-vip_contents_info_level-up">REFER A
																FRIEND</h2>
                            <p className="landing_go-frens_contents_info_description">
																Give $30 in discounts to your friends
                                {dpayStatus ? `, and get $20 in ${import.meta.env.VITE_TOKEN_NAME} ` : " "}
																when they place their first order!
                            </p>
                            <FrameButton
                                <ButtonHTMLAttributes<HTMLButtonElement>>
                                color="purple"
                                size="narrow"
                                className="landing_refer_contents_button"
                                onClick={() => {
                                    const link = isDesktop
                                        ? "/account/#referafriend"
                                        : "/refer-a-friend";
                                    history(!fullToken
                                        ? `/log-in?redirect=${link}`
                                        : link);
                                }}
                            >
																Invite your friends
                            </FrameButton>
                        </div>

                        {isMobile && !isTablet && renderReferGraphics()}

                    </div>
                </div>

                {/* RESTAURANT OWNER SECTION*/}
                <div className="landing_restaurant-owner">
                    <h2 className="landing_go-vip_contents_info_level-up
                        landing_restaurant-owner_description_margin-adjust"
                    >
												RESTAURANT OWNER?
                    </h2>
                    <p className="landing_restaurant-owner_description landing_restaurant-owner_description_margin-adjust">
												Add your location to DevourGO!
                    </p>
                    <p className="landing_restaurant-owner_description">Reach out to us today.</p>
                    <FrameButton
                        <AnchorHTMLAttributes<HTMLAnchorElement>>
                        color="purple"
                        size="narrow"
                        href="mailto:hello@devourgo.io?subject=Partner With Us"
                    >
												Partner with us
                    </FrameButton>
                </div>

            </div>
        </>
    );
}

export default Landing;
