import {ReactElement} from "react";
import {FiChevronRight} from "react-icons/fi";
import FrameButton from "../buttons/FrameButton";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "./autoPanelComponents/FrameAutoPanelHeader";
import {GoVipLevelsCardData} from "../goVip/goVipText";
import GoVipLevelsCard from "../goVip/GoVipLevelsCard";
import {useNavigate} from "react-router";
import useThemePreference from "@/hooks/useThemePreference";
import {useGate} from "statsig-react";

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function GoVipLevelsModal(props: Props): ReactElement {
    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);
    const {isOnDarkMode} = useThemePreference();
    const navigate = useNavigate();

    const goVipLevelsText: Array<GoVipLevelsCardData> = [
        {
            level: 1,
            prize: "$5.00 Off Order Promo Code",
        },
        {
            level: 2,
            prize: "GoVIP Promo NFT",
        },
        {
            level: 3,
            prize: "NFT Loot Box #1",
        },
        {
            level: 4,
            prize: "DevourGO T-Shirt",
        },
        {
            level: 5,
            prize: "$20 Off Order Promo Code",
        },
        {
            level: 6,
            prize: "NFT Loot Box #2",
        },
        {
            level: 7,
            prize: "$30 Off Order Promo Code",
        },
        {
            level: 8,
            prize: "DevourGO Sweatshirt",
        },
        {
            level: 9,
            prize: dpayStatus ? `$40 in ${import.meta.env.VITE_TOKEN_NAME}` : "N/A",
        },
        {
            level: 10,
            prize: "$50 Off Order Promo Code",
        },
        {
            level: 11,
            prize: "NFT Loot Box #3",
        },
    ];


    function renderLevelCards(levelData: Array<GoVipLevelsCardData>) {
        return levelData.map((level) =>
            <GoVipLevelsCard levelsData={level} key={`govip-level-card-${level.level}`}/>);
    }

    return (
        <>
            <FrameOneAutoPanel
                isOpen={props.isOpen}
                toggle={props.toggle}
                maxHeight={true}
                size="sm"
            >
                <FrameAutoPanelHeader
                    title="GoVIP Level Completion Prizes"
                    toggle={props.toggle}
                />

                <div className="go-vip-levels_body">
                    <div className="go-vip-levels_body_description">
                        <div className="go-vip-levels_body_description_text">
                            <p>
																You're now on the path to increasingly valuable rewards including
																DevourGO merch,
																fantasy dining
																trips and $20K in Ethereum!
                            </p>
                            <br/>
                            <p>
																Attain all 12 levels by earning XP ordering food, Share2Earn,
																Play2Earn, and other activities.
																Once you earn the XP to complete a level, you will receive the
																corresponding
                                {dpayStatus ? ` ${import.meta.env.VITE_TOKEN_NAME} and ` : " "}
																prize reward.
                            </p>
                            <br/>
                            <p>
																XP also raise your rank on the leaderboards, which carry their
																own rewards!
                            </p>
                            <br/>
                            <p className="go-vip-levels_body_description_text_disclaimer">
																Holding <a target="_blank" rel="noreferrer"
																					 href="https://devour.io/theindustry/">
																The Industry Pass</a> in a registered digital wallet is required to
																advance past level 3
																and earn
																the biggest prizes.
                            </p>
                        </div>
                    </div>

                    <div className="go-vip-levels_body_cards-container">

                        {renderLevelCards(goVipLevelsText.slice(0, 3))}

                        <div className="go-vip-levels_body_cards-container_prompt-container">
                            <div
                                className="go-vip-levels_body_cards-container_prompt-container_prompt">
                                <p
                                    className="go-vip-levels_body_cards-container_prompt-container_prompt_text
                                go-vip-levels_body_cards-container_prompt-container_prompt_title"
                                >
																		Important
                                </p>
                                <p className="go-vip-levels_body_cards-container_prompt-container_prompt_text">
																		Further level ups require ownership of an NFT from the Industry
																		Collection.
                                </p>

                                <div
                                    className="go-vip-levels_body_cards-container_prompt-container_prompt_mint-container">
                                    <FrameButton
                                        color={isOnDarkMode
                                            ? "white-drop-shadow-devour"
                                            : "white-drop-shadow"}
                                        size="pill"
                                        className="go-vip-levels_body_cards-container_prompt-container_prompt_mint-container_button"
                                        rightIcon={FiChevronRight}
                                        onClick={() => navigate("/the-industry-pass")}
                                    >
																				Mint now
                                    </FrameButton>
                                </div>

                            </div>
                        </div>

                        {renderLevelCards(goVipLevelsText.slice(3, goVipLevelsText.length))}

                    </div>
                </div>

            </FrameOneAutoPanel>
        </>
    );
}

export default GoVipLevelsModal;
