import {AnchorHTMLAttributes, ReactElement, useState} from "react";
import FrameButton from "@/components/buttons/FrameButton";
import {isDesktop, isTablet} from "react-device-detect";
import GoVipOWTutorialModal from "@/components/goVip/GoVipOWTutorialModal";

export default function GoVipOWEarnPoints(): ReactElement {
    const [showTutorialModal, setShowTutorialModal] = useState<boolean>(false);
    const isOnMobile = !isDesktop && !isTablet;

    return (
        <div className="go-vip-overwolf_earn-exp">
            <GoVipOWTutorialModal
                isOpen={showTutorialModal}
                onClose={() => setShowTutorialModal(false)}
            />
            <div className="go-vip-overwolf_earn-exp_text">
                <h3 className="go-vip-overwolf_heading">
                    Earn XP {isOnMobile ? "&" : "and"} rewards with Overwolf
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/go-vip-overwolf-logo.webp`}
                        alt="overwolf-logo"
                    />
                </h3>
                <div className="go-vip-overwolf_earn-exp_text_description">
                    Download the DevourGO+ app from the Overwolf Appstore and start earning XP while playing your
                    favorite games! {isOnMobile ? "" : "What are you waiting for?"}
                </div>
            </div>

            <div className="go-vip-overwolf_earn-exp_buttons">
                {!isOnMobile && <FrameButton
                    <AnchorHTMLAttributes<HTMLAnchorElement>>
                    size="narrow"
                    className="go-vip-overwolf_earn-exp_buttons_download"
                    href={"https://www.overwolf.com/app/devourgo"} // TODO: Add correct link
                    forwardProps={{
                        target: "_blank",
                        rel: "noopener noreferrer",
                    }}
                >
                    Download to PC
                </FrameButton>}

                <FrameButton
                    size="narrow"
                    className="go-vip-overwolf_earn-exp_buttons_learn-more"
                    onClick={() => setShowTutorialModal(true)}
                >
                    Learn More
                </FrameButton>
            </div>
        </div>
    );
}