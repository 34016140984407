/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountExperienceTransaction,
    AccountExperienceTransactionFromJSON,
    AccountExperienceTransactionToJSON,
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    OWUserStats,
    OWUserStatsFromJSON,
    OWUserStatsToJSON,
} from '../models';

export interface GetOverwolfUserStatsRequest {
    gameId?: string;
    rewardId?: string;
    rewardCategory?: GetOverwolfUserStatsRewardCategoryEnum;
}

export interface GetOverwolfXpRequest {
    subject: GetOverwolfXpSubjectEnum;
    overwolfRewardId?: string;
}

/**
 * OverwolfApi - interface
 * @export
 * @interface OverwolfApiInterface
 */
export interface OverwolfApiInterface {
    /**
     * Retrieves Overwolf user stats.
     * @param {string} [gameId] Optional Overwolf game ID to filter user stats.
     * @param {string} [rewardId] Optional reward ID to filter user stats.
     * @param {'EVENT' | 'TIME'} [rewardCategory] Optional reward category (\&quot;EVENT\&quot; or \&quot;TIME\&quot;) to filter user stats.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverwolfApiInterface
     */
    getOverwolfUserStatsRaw(requestParameters: GetOverwolfUserStatsRequest): Promise<runtime.ApiResponse<Array<OWUserStats>>>;

    /**
     * Retrieves Overwolf user stats.
     */
    getOverwolfUserStats(requestParameters: GetOverwolfUserStatsRequest): Promise<Array<OWUserStats>>;

    /**
     * Retrieves Overwolf-related account experience transactions for a user.
     * @param {'OVERWOLF_TIME_REWARD' | 'OVERWOLF_EVENT_REWARD'} subject The subject of the transaction. Must be one of the specified enum values.
     * @param {string} [overwolfRewardId] Optional Overwolf reward ID to filter transactions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverwolfApiInterface
     */
    getOverwolfXpRaw(requestParameters: GetOverwolfXpRequest): Promise<runtime.ApiResponse<Array<AccountExperienceTransaction>>>;

    /**
     * Retrieves Overwolf-related account experience transactions for a user.
     */
    getOverwolfXp(requestParameters: GetOverwolfXpRequest): Promise<Array<AccountExperienceTransaction>>;

}

/**
 * no description
 */
export class OverwolfApi extends runtime.BaseAPI implements OverwolfApiInterface {

    /**
     * Retrieves Overwolf user stats.
     */
    async getOverwolfUserStatsRaw(requestParameters: GetOverwolfUserStatsRequest): Promise<runtime.ApiResponse<Array<OWUserStats>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.gameId !== undefined) {
            queryParameters['gameId'] = requestParameters.gameId;
        }

        if (requestParameters.rewardId !== undefined) {
            queryParameters['rewardId'] = requestParameters.rewardId;
        }

        if (requestParameters.rewardCategory !== undefined) {
            queryParameters['rewardCategory'] = requestParameters.rewardCategory;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/overwolf/user-stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OWUserStatsFromJSON));
    }

    /**
     * Retrieves Overwolf user stats.
     */
    async getOverwolfUserStats(requestParameters: GetOverwolfUserStatsRequest): Promise<Array<OWUserStats>> {
        const response = await this.getOverwolfUserStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieves Overwolf-related account experience transactions for a user.
     */
    async getOverwolfXpRaw(requestParameters: GetOverwolfXpRequest): Promise<runtime.ApiResponse<Array<AccountExperienceTransaction>>> {
        if (requestParameters.subject === null || requestParameters.subject === undefined) {
            throw new runtime.RequiredError('subject','Required parameter requestParameters.subject was null or undefined when calling getOverwolfXp.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.overwolfRewardId !== undefined) {
            queryParameters['overwolfRewardId'] = requestParameters.overwolfRewardId;
        }

        if (requestParameters.subject !== undefined) {
            queryParameters['subject'] = requestParameters.subject;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/overwolf/xp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccountExperienceTransactionFromJSON));
    }

    /**
     * Retrieves Overwolf-related account experience transactions for a user.
     */
    async getOverwolfXp(requestParameters: GetOverwolfXpRequest): Promise<Array<AccountExperienceTransaction>> {
        const response = await this.getOverwolfXpRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetOverwolfUserStatsRewardCategoryEnum {
    EVENT = 'EVENT',
    TIME = 'TIME'
}
/**
    * @export
    * @enum {string}
    */
export enum GetOverwolfXpSubjectEnum {
    TIMEREWARD = 'OVERWOLF_TIME_REWARD',
    EVENTREWARD = 'OVERWOLF_EVENT_REWARD'
}
