import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import classNames from "classnames";
import {isDesktop, isTablet} from "react-device-detect";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import {AccountExperienceTransaction} from "@devour/client";
import {FiX} from "react-icons/fi";
import FrameButton from "@/components/buttons/FrameButton";
import {AnchorHTMLAttributes, ReactElement} from "react";
import useWindowSize from "@/hooks/useWindowSize";
import GoVipOWTimeBasedRewardCard from "@/components/goVip/GoVipOWTimeBasedRewardCard";
import useThemePreference from "@/hooks/useThemePreference";


interface Props {
    isOpen: boolean;
    onClose: () => void;
    rewards: Array<AccountExperienceTransaction>;
}

export default function GoVipOWTimeBasedRewardsModal(props: Props) {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const screenWidth = useWindowSize()[0];
    const {isOnDarkMode} = useThemePreference();

    function renderRewardsContent(): ReactElement {
        const emptyDescription: ReactElement = screenWidth <= 400
            ? <>No XP yet, but the more you play,<br/>the more you earn. Jump into a game and<br/>play for an hour to earn your first XP!</>
            : <>No XP yet, but the more you play,<br />the more you earn. Jump into a game and play<br />for an hour to earn your first XP!</>;

        if (props.rewards?.length > 0) {
            return (
                <div className="go-vip-ow_rewards-modal_body_list">
                    {props.rewards.map((reward: AccountExperienceTransaction) =>
                        <GoVipOWTimeBasedRewardCard reward={reward} key={reward.id}/>)}
                </div>
            );
        }

        return (
            <div className="go-vip-ow_rewards-modal_body_empty">
                <img src={`${import.meta.env.VITE_CDN_URL}/images/govip-ow-time-rewards-modal-empty.webp`} alt="GoVIP Overwolf Rewards Empty"/>
                <h5>Haven't played enough?</h5>
                <span className="go-vip-ow_rewards-modal_body_empty_description">
                    {emptyDescription}
                </span>
            </div>
        );
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName={classNames("go-vip-ow_rewards-modal", {
                "authenticated": !!fullToken,
            })}
            size="xs2"
            modalOnTablet={true}
            fullScreenHeight={!!fullToken}
        >
            <div
                style={{
                    backgroundImage: `url(${import.meta.env.VITE_CDN_URL}/images/govip-ow-time-rewards-modal-bg-${isDesktop || isTablet ? "lg" : "sm"}.webp)`,
                }}
                className="go-vip-ow_rewards-modal_header"
            >
                <button className="reset-button go-vip-ow_rewards-modal_header_close">
                    <FiX
                        size={isOnDarkMode ? "2rem" : "1.5rem"}
                        strokeWidth="0.175rem"
                        onClick={props.onClose}
                    />
                </button>
                <div className="go-vip-ow_rewards-modal_header_text">
                    <img src={`${import.meta.env.VITE_CDN_URL}/images/govip-ow-devour-logo.webp`} alt="DevourGO logo"/>
                    <h1>{fullToken ? "TRACK REWARDS" : "PLAY TO EARN"}</h1>

                    <div className="go-vip-ow_rewards-modal_header_text_description">
                        Earn 10 XP for every hour of gameplay!<br />The more you play, the more XP you collect.
                    </div>

                    <div className="go-vip-ow_rewards-modal_header_text_disclaimer">
                        * Max XP you can earn is 1,000 per week.
                    </div>


                </div>
            </div>

            <FrameAutoPanelBody className="go-vip-ow_rewards-modal_body">
                {!fullToken
                    ? <FrameButton
                        <AnchorHTMLAttributes<HTMLAnchorElement>>
                        size="normal"
                        className="go-vip-ow_rewards-modal_body_download-btn"
                        href={"https://www.overwolf.com/app/devourgo"} // TODO: Add correct link
                        forwardProps={{
                            target: "_blank",
                            rel: "noopener noreferrer",
                        }}
                    >
                        Download to PC
                    </FrameButton>
                    : renderRewardsContent()
                }
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}